@import '../../_styles/colors';
@import '../../_styles/constants';

.segmented-control {
  display: flex;
  align-items: center;
  font-size: $font-size;

  &.large {
    font-size: 1.8rem;
  }

  button.plain {
    display: block;
    color: $text-color;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 0.2rem;
      background-color: transparent;
      margin-top: 0.3rem;
    }

    &.active {
      font-weight: bold;
      color: $primary-color;
      &:after {
        background-color: $primary-color;
      }
    }
  }

  button + button {
    margin-left: 3.5rem;
  }
}
