$darkblue: #004494;

.meetingbooked-box {
  margin: auto;
  margin-top: 8rem;
  width: 50%;
  padding: 1rem 4rem 4rem;
  box-shadow: 0.313px 0.313px 3.125px rgba(64, 64, 64, 0.1);
  border-radius: 0.5rem;

  .go-back {
    .icon {
      svg {
        color: $darkblue;
      }
    }

    span {
      color: $darkblue;
    }
  }

  .meetingbooked-container {
    margin-top: 4rem;
    display: flex;

    i.icon {
      margin-top: 0.8rem;
      margin-right: 2.5rem;

      svg {
        path:first-child {
          fill: #e7eefd;
        }
        path:last-child {
          fill: $darkblue;
        }
      }
    }

    .meetingbooked-textwrapper {
      border-left: 1px solid #d7d7d7;
      padding-left: 2rem;

      .meetingbooked-header {
        font-weight: bold;
        font-size: 2.2rem;
        margin-bottom: 1.5rem;
      }

      .meetingbooked-text {
        font-size: 1.5rem;
        line-height: 2.5rem;
      }
    }
  }
}
