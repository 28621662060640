$darkblue: #004494;
$lightblue: #f1f5fe;
$black: #404040;

.calendar {
  .error-message {
    width: fit-content;
    margin: auto;
    margin-top: 10rem;
  }

  .prev-month,
  .next-month {
    width: 3rem;
    height: 3rem;
    background-color: transparent;
  }

  .next-month {
    margin-left: auto;
  }

  .calendar-box {
    margin: 10rem auto 0;
    width: fit-content;
    box-shadow: 0.313px 0.313px 3.125px rgba(64, 64, 64, 0.1);
    border-radius: 0.5rem;
    background-color: white;

    .meeting-confirm {
      background-color: $lightblue;
      width: 100%;
      display: flex;
      padding: 1rem;

      .meeting-box {
        margin-left: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6rem;
        width: 100%;

        .meeting-wrapper {
          display: flex;
          gap: 0.6rem;
          align-items: center;
          justify-content: center;

          .meeting-header {
            font-weight: bold;
            font-size: 1.4rem;
          }

          .meeting-date {
            font-size: 1.4rem;
          }
        }

        .confirm-button-wrapper {
          margin-left: auto;
          margin-right: 3rem;
          width: 13rem;
          height: 3.7rem;

          .confirm-button {
            background-color: $darkblue;
            color: white;
            border-radius: 0.8rem;
            width: 100%;
            height: 100%;

            font-weight: bold;
          }
        }
      }
    }

    .calendar-header {
      font-weight: bold;
      font-size: 2.3rem;
      padding-top: 3rem;
      padding-left: 4rem;
      margin-bottom: 1rem;
    }

    .calendar-container {
      justify-content: center;
      display: flex;
      overflow: auto;

      .react-datepicker {
        border-radius: 0;
        box-shadow: none;
        background-color: white;
        height: 35rem;
        border: 0;
        height: fit-content;

        .react-datepicker__month-container {
          padding-right: 1rem;
          padding-left: 2.5rem;
          height: 35rem;

          .react-datepicker__current-month {
            text-align: left;
            font-size: 1.4rem;
            margin-bottom: 1.5rem;
            color: $black;
            padding-left: 1.5rem;
          }

          .react-datepicker__day.selected:not(.react-datepicker__day--outside-month) {
            color: white;
            background-color: $darkblue;
            font-weight: bold;
          }

          .react-datepicker__day:not(.react-datepicker__day--disabled):not(.selected):not(.react-datepicker__day--outside-month) {
            color: $darkblue;
            background-color: $lightblue;
            font-weight: bold;

            &:hover {
              color: white;
              background-color: $darkblue;
              font-weight: bold;
            }
          }

          .react-datepicker__day--today {
            color: white;
            background-color: transparent;
          }

          .react-datepicker__day-name {
            color: $black;
            font-size: 1.2rem;
            text-transform: uppercase;
          }

          .react-datepicker__day--today {
            background-color: transparent;
          }

          .react-datepicker__day--disabled {
            color: grey;
          }

          .react-datepicker__day--outside-month {
            color: transparent;
            user-select: none;
          }
        }
      }

      .timeslots-container {
        padding-left: 2rem;
        overflow-y: hidden;
        background-color: white;
        padding-right: 2rem;

        .timeslots-box {
          border-left: 1px solid #d7d7d7;
          padding-left: 3rem;
          width: auto;
          background-color: white;

          .timeslots-header {
            font-size: 1.4rem;
            font-weight: bold;
            margin-top: 1.5rem;
            background-color: white;
          }

          .timeslots-list {
            margin-top: 2.4rem;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            height: 25rem;
            width: 15rem;
            overflow-y: scroll;
            background-color: white;

            .timeslots-listitem {
              border-radius: 0.8rem;
              border: 1px $darkblue solid;
              color: $darkblue;

              margin: 0.6rem 0;
              width: 13rem;
              height: 3.7rem;

              font-weight: bold;
              text-align: center;
              line-height: 3.7rem;
              background-color: white;

              // Make text unselectable
              -moz-user-select: -moz-none;
              -khtml-user-select: none;
              -webkit-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }

            .timeslots-listitem:hover {
              background-color: #004494;
              background: #004494;
              color: white;
              cursor: pointer;
            }

            .timeslots-listitem.selected {
              background-color: #004494;
              background: #004494;
              color: white;
            }
          }

          .timeslots-list::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera */
          }
        }
      }
    }
  }
}
