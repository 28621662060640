.login {
  .input-wrapper {
    .input {
      width: 100%;
    }
  }

  .actions {
    justify-content: space-between;
  }
}
