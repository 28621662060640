@import '../../_styles/colors';
@import '../../_styles/constants';

/* This file overwrites the default css from the npm package */
$color-disabled: darken($white, 35);
$item-size: 3.25rem;

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__input-container {
  display: block;
  position: static;
}

.react-datepicker-popper {
  z-index: 100;

  &[data-placement^='bottom'],
  &[data-placement^='top'] {
    margin-top: 1rem;
    .react-datepicker__triangle {
      border-bottom-color: $primary-color;
      border-top-color: $primary-color;
      left: 4rem;
      &:before {
        border: 0;
      }
    }
  }
  &[data-placement^='top'] {
    margin-bottom: 7.5rem;
  }
}

.react-datepicker {
  font-family: $font-family;
  font-size: 1.4rem;
  background-color: $primary-color;
  box-shadow: 0.8rem 0.8rem 5.4rem 0 $shadow;
  font-weight: normal;
  border: 1px solid $primary-color;
  border-radius: 2rem;
  margin-top: 0.2rem;

  .react-datepicker__header {
    background-color: transparent;
    border: 0;
    padding-top: 0;
    padding-bottom: 1rem;
  }
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    border: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 2.4rem;
    height: 2.4rem;
    top: 1.6rem;
  }
  .react-datepicker__navigation--previous {
    background-image: url('../../_assets/svg/chevron_left.svg');
    left: 2rem;
  }
  .react-datepicker__navigation--next {
    background-image: url('../../_assets/svg/chevron_right.svg');
    right: 2rem;
  }

  .react-datepicker__month {
    margin: 0;
    padding: 0 1.6rem;
    padding-bottom: calc(1.6rem + 0.3rem);
  }
  .react-datepicker__current-month {
    padding: 1.6rem 0;
    font-size: $font-size;
    color: $white;
    font-weight: 700;
    text-transform: capitalize;
  }

  .react-datepicker__day {
    height: $item-size;
    width: $item-size;
    line-height: $item-size;
    margin: 0.3rem;
    color: $white;
    border-radius: 50%;
  }
  .react-datepicker__day-name {
    width: $item-size;
    line-height: 1.2rem;
    margin: 0.3rem;
    color: $color-disabled;
    font-weight: 700;
    text-transform: capitalize;
  }
  .react-datepicker__day--today {
    background-color: darken($white, 20);
    color: $primary-color;
  }
  .react-datepicker__day:hover,
  .react-datepicker__day--selected {
    background-color: $white;
    color: $primary-color;
    font-weight: 700;
  }
  .react-datepicker__day--outside-month {
    color: $color-disabled;
  }
  .react-datepicker__day--disabled {
    color: $color-disabled;
    pointer-events: none;
  }
}
