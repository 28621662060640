@import '../../../../_styles/colors';
@import '../../../../_styles/constants';

$height-toolbar: 4.3rem;

.quill {
  min-height: calc(#{$height-toolbar} + 5rem);

  .ql-toolbar,
  .ql-container {
    border-color: $shadow;
    color: $text-color;
  }

  .ql-container {
    font-size: $font-size;
    height: calc(100% - #{$height-toolbar});

    .ql-editor.ql-blank::before {
      font-style: normal;
      color: $placeholder-color;
    }
  }

  &.error {
    .ql-toolbar,
    .ql-container {
      border-color: $error-border-color;
    }

    .ql-container {
      background: $error-background-color;

      .ql-editor.ql-blank::before {
        color: $error-placeholder-color;
      }
    }
  }

  &.active {
    > div {
      border-color: $primary-color;
    }
    .ql-toolbar,
    .ql-container {
      color: $text-color;
    }
    &.error {
      .ql-toolbar,
      .ql-container {
        border-color: $red;
      }
    }
  }

  &.disabled {
    opacity: $disabled-opacity;
    > div {
      border-color: $shadow;
      background-color: $disabled-background-color;
      pointer-events: none;
    }
  }
}
