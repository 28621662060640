@import '../../../_styles/colors';
@import '../../../_styles/constants';

.input-date-wrapper {
  position: relative;
  .ui.input > input {
    padding-right: 4rem;
  }

  button.datepicker-icon {
    position: absolute;
    right: 1rem;
    height: $input-height;
    top: 0;

    & > * {
      color: $primary-color;
    }
  }

  &.has-label {
    .datepicker-icon {
      top: calc(#{$input-icon-height} + #{$input-label-bottom-margin});
    }
  }
}
