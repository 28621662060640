.profile {
  margin: auto;
  margin-top: 8rem;
  width: 35%;

  p {
    margin-bottom: 2rem;
  }

  button:first-of-type {
    margin-right: 2rem;
  }
}
