@import '../../../_styles/colors';
@import '../../../_styles/constants';

.textarea-wrapper {
  .textarea {
    width: 100%;
    outline: none;
    border-radius: none;
    padding: 1rem;
    border: 1px solid $input-border-color;
    color: $text-color;
    font-size: $font-size;
    font-family: $font-family;
    box-sizing: border-box;
    resize: vertical;

    &:focus {
      border: 1px solid $primary-color;
    }

    &:disabled {
      opacity: $disabled-opacity;
      background-color: $disabled-background-color;
    }

    &::placeholder {
      color: $placeholder-color;
    }

    &.error {
      color: $error-text-color;
      border-color: $error-border-color;
      background-color: $error-background-color;

      &::placeholder {
        color: $error-placeholder-color;
      }

      &:focus {
        border-color: $error-border-color;
      }
    }
  }

  .character-limit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $grey;
    font-size: 1.4rem;

    &.reached {
      color: $red;
    }
  }
}
