@import '../../../../_styles/colors';
@import '../../../../_styles/constants';

.main-menu {
  height: $navbar-height;
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $primary-color;
  border-bottom: 1px solid $shadow;
  box-shadow: 0 1px 2px 0 $shadow;
  padding: 0 2rem;
  color: $white;

  img {
    height: 4rem;
  }

  nav {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    padding: 0 2rem;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 70%;
      top: 15%;
      background-color: $white;
      position: absolute;
      right: 2rem;
      opacity: 0.2;
    }

    > div {
      display: flex;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      padding: 2.2rem 2.4rem;
      font-size: 1.4rem;
      font-weight: bold;
      text-transform: uppercase;
      color: $white;
      opacity: 0.5;

      &:hover {
        opacity: 0.7;
      }

      &.active {
        opacity: 1;
      }

      > :not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  button {
    color: $white;
  }
}
