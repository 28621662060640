$DEFAULT_FORM_WIDTH: 100rem;

.authorized-layout {
  .container,
  .left-container {
    padding: 0 8rem 2rem;
  }
  .container {
    overflow-x: auto;
  }
  .left-container {
    width: $DEFAULT_FORM_WIDTH;
    max-width: $DEFAULT_FORM_WIDTH;
  }
}
