$font-size: 1.6rem;
$font-family: 'Roboto', sans-serif;

$border-radius: 0.25rem;
$disabled-opacity: 0.75;
$navbar-height: 6.5rem;
$input-height: 4rem;

$input-icon-height: 2.2rem;
$input-label-bottom-margin: 0.5rem;
