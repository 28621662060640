@import '../../_styles/colors';
@import '../../_styles/constants';

$border: 1px solid $grey-light;

.table {
  table-layout: fixed;
  width: 100%;
  border: $border;

  thead {
    background-color: $grey-lighter;
    border-bottom: $border;

    th {
      padding: 1.4rem 1.1rem;
      text-align: left;

      &.sortable {
        cursor: pointer;
        &:hover {
          background-color: $grey-light;
        }
        &:after {
          padding-left: 0.5rem;
          color: $grey;
        }
        &.asc {
          &:after {
            content: '\2191';
          }
        }
        &.desc {
          &:after {
            content: '\2193';
          }
        }
      }
    }
  }

  tbody {
    tr {
      &:not(:first-child) {
        border-top: $border;
      }
      &.greyed-out {
        background-color: $grey-light;
      }
    }

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 1.1rem;
      border: 0;

      &.centered-text {
        text-align: center;
      }

      &.table-empty {
        padding: 2rem;
        font-size: 1.4rem;
      }
    }

    tr.row-spinner {
      border-top: 0;
    }
  }
}
