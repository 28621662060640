@import './_styles/colors';

.toast-container {
  .toast {
    background-color: $primary-color;
    border-radius: 0.4rem;
    color: $white;
    font-size: 1.4rem;
    font-family: Roboto, sans-serif;
    padding: 1.6rem;
    padding-right: 1rem;
    cursor: default;
    display: flex;
    align-items: center;

    .toast-body {
      padding-right: 1rem;
    }

    .icon {
      cursor: pointer;
      color: $white;
      height: 2.4rem;
    }
    &.Toastify__toast--error {
      background-color: $red;
    }
  }

  .toast-progress {
    background: $white-transparent;
  }
}
