.set-availabilities {
  margin: auto;
  margin-top: 8rem;
  width: 35%;

  h1 {
    margin-bottom: 1rem;
  }

  .actions {
    padding: 1rem;
  }

  .days {
    width: fit-content;

    .day {
      display: flex;
      margin-top: 1.5rem;
      border-bottom: 1px solid grey;

      .unavailable {
        color: darkgray;
        margin-top: 0.6rem;
        margin-bottom: -0.6rem;
        height: 9rem;
      }

      .checkbox-available {
        margin-top: 0.8rem;
        margin-right: 1rem;
      }

      .dayName {
        margin-top: 0.5rem;
        margin-right: 1rem;
        width: 6rem;
      }

      .rows {
        display: block;
        margin-left: 0.1rem;

        .availability-row {
          display: flex;
          height: 9rem;
          padding-bottom: 2rem;

          .input-wrapper {
            height: fit-content;
            width: 10rem;
          }
          // hide clock in time input field
          input[type='time']::-webkit-calendar-picker-indicator {
            background: none;
            display: none;
          }

          .dash {
            margin: 0.8rem 1rem 0;
          }

          .addRowButton,
          .deleteRowButton {
            margin-left: 4rem;
            width: fit-content;
            height: fit-content;
          }
        }
      }
    }
    .day:last-child {
      border-bottom: none;
    }
  }
}
